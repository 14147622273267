import { createClient } from 'redis';

import Home from "../components/Home";
import JsonLdHome from "../components/Schema/jsonLdHome";

import { initializeApollo } from "../lib/apollo-client";
import { getWebsiteID } from "../lib/common-functions"
import { useIsMobile } from "/lib/hooks/useIsMobile";
import isMobileDetect from "/lib/services/helpers/isMobileDetect";

import CMS_QUERY from "../components/homepage.graphql";
import DESKTOP_STORE_QUERY from "../components/layout/homepage_seo.graphql";
import CategoryProductMenu from '/components/CategoryProductMenu';
import { getStoreType } from '/lib/services/helpers/getStoreType';
import { getWebsiteURL } from '/lib/services/helpers/getWebsiteURL';

function HomePage(props) {
  const { cms_data, homePageSeoData, website_id, isSsrMobile, headers, country } = props;
  const isMobileType = useIsMobile(isSsrMobile)
  const websiteurl = getWebsiteURL(website_id);

  console.log('first', headers);
  console.log('first 2', country);

  if (!cms_data || !homePageSeoData) return <div>⌚️ Loading...</div>;

  return (
    <>
      <JsonLdHome />
      {
        isMobileType ? (
          <CategoryProductMenu
            websiteurl={websiteurl}
          />
        ) : null
      }
      <Home
        cms_data={cms_data}
        homePageSeoData={homePageSeoData}
        isSsrMobile={isSsrMobile}
        categoryIcon={cms_data?.homepage_banner_promotion9_text}
      />
    </>
  );
}

export const getServerSideProps = async (ctx) => {
  const { req, query: { key = '', action = '' } } = ctx;
  const headers = req.headers;
  const country = headers['cf-ipcountry'] || '';
  console.log('headers', headers)
  console.log('country', country);


  const store = getStoreType(req);
  const apolloClient = initializeApollo("", store);
  const website_id = getWebsiteID(req.cookies)

  try {
    let cms_data_redis
    let cms_data

    const client = createClient({
      url: process.env.REDIS_ENDPOINT
    });

    client.on('error', (err) => console.log('Redis Client Error', err));

    await client.connect();

    if (key != process.env.NEXT_REDIS_CLEAN_KEY || action != 'purge') {
      cms_data_redis = JSON.parse(await client.get(`cms_data_${store}`));
    }

    if (cms_data_redis) {
      cms_data = cms_data_redis
    } else {
      cms_data = await apolloClient.query({
        query: CMS_QUERY,
      })
      await client.del(`blog_home_${store}`);
      await client.del(`product_data_homepage_1_${store}`)
      await client.del(`product_data_homepage_2_${store}`)
      await client.del(`product_data_homepage_3_${store}`)
      await client.del(`product_data_homepage_gwp_lower_slab_skus_${store}`)
      await client.del(`product_data_homepage_amaaya_slab_skus_${store}`)
      await client.del(`product_data_homepage_gwp_upper_slab_skus_${store}`)
      await client.set(`cms_data_${store}`, JSON.stringify(cms_data))
      await client.expire(`cms_data_${store}`, process.env.NEXT_PUBLIC_TTL2)
    }

    let homeSeoData_redis
    let homeSeoData
    homeSeoData_redis = JSON.parse(await client.get(`seohomepage_data_${store}`));

    if (homeSeoData_redis) {
      homeSeoData = homeSeoData_redis
    } else {
      homeSeoData = await apolloClient.query({
        query: DESKTOP_STORE_QUERY,
      });
      await client.set(`seohomepage_data_${store}`, JSON.stringify(homeSeoData))
      await client.expire(`seohomepage_data_${store}`, process.env.NEXT_PUBLIC_TTL2)
    }

    await client.disconnect();
    return {
      props: {
        isSsrMobile: isMobileDetect(req), cms_data: cms_data?.data?.storeConfig || [], website_id, homePageSeoData: homeSeoData?.data?.storeConfig || [],
        headers, country
      },
    };
  } catch (error) {
    return {
      props: {
        isSsrMobile: isMobileDetect(req),
        cms_data: [],
        homePageSeoData: []
      },
    };
  }
};

export default HomePage;
